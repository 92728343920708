import Vue from 'vue'
import UserResource from 'common/services/resources/user.js'
import Page from 'common/components/page/page.vue'
import AcceptTerms from '../accept-terms/accept-terms.vue'
import Captcha from 'common/components/captcha/captcha.vue'
import WindowService from 'common/services/window.js'
import LanguageService from 'common/services/language.js'
import store from 'vuex_path/store'

export default {
	components: {Page, AcceptTerms, Captcha},
	props: ['displayLogo'],
	data:
		function () {
			return {
					CONSTANTS:
					{
						SELECT_REGION : this.$gettext('Select a region'),
						TITLE : this.$gettext((store.state.variantManager.getSubscribe().title?store.state.variantManager.getSubscribe().title:'Your new comfort of life')),
						SUBSCRIBE_ERROR : this.$gettext('Unable to create the account'),
						CAPTCHA_ERROR : this.$gettext('Invalid captcha!'),
						ACCOUNT_ALREADY_CREATE_ERROR : this.$gettext('This email is already used for an account'),
						SUBSCRIBE_SUCCESS: this.$gettext('Your account has been successfully created.<br/>An email has been sent to the \'login\' email address to activate this account'),
						SELECT_REGION_ERROR: this.$gettext('Please select a region'),
                        PASSWORD_DONT_MATCH : this.$gettext('The confirmation password don\'t match'),
                        PASSWORD_TOO_SHORT : this.$gettext('Your password must contain at least 8 characters'),
					},
					formstate: {},
					user:
					{
						firstname:'',
						lastname:'',
						username:'',
						acceptCgu: false,
						password:'',
						confirmPassword:''
					},
					hidePassword: true,
					hideConfirmPassword: true,
					captcha: {
							code:'',
							id:''
					},
					modalSubscribeConfirm:
					{
						content: '',
						visible: false
					},
					selectedIndexRegion: '1',
					regions: LanguageService.CONSTANTS.REGIONS,
					loading:false,
					selectedRegion: {}
			}
		},
    created: function () {
		this.eventHub.$emit('hideNavbar');
		this.eventHub.$on('modal', this.modal);
		this.eventHub.$on('inputCaptcha', this.inputCaptcha);
		this.selectedIndexRegion = LanguageService.getIndexFromUrl((this.inApp?'eu':undefined));
		this.selectedRegion = this.regions[this.selectedIndexRegion];
	},
	computed: {
        checkPwdValidator: function () {
			return this.user.confirmPassword!='' && this.user.password!='' && this.user.confirmPassword === this.user.password;
        }
    },
	methods: {
		back: function () {
			this.eventHub.$emit('goTo','login');
		},
		fieldClassName: function (field) {
			if(!field) {
				return '';
			}
			if((field.$touched || field.$submitted ||  field.$dirty ) && field.$valid) {
				return 'has-success';
			}
			if((field.$touched || field.$submitted || field.$dirty ) && field.$invalid) {
				return 'has-danger';
			}
		},
		acceptCguValidator: function () {
			return this.user.acceptCgu;
		},

		checkSubscribe: function()
		{
			this.screen = 'subscribe';
		},
		init: function()
		{
			var username = this.user.username;
			var password = this.user.password;
			this.hidePassword = true;
			this.hideConfirmPassword = true;
			this.eventHub.$emit('setUserLogin', {username: username, password : password, hidePassword: true,rememberMe: true});
			
			for(var x in this.user)
			{
				this.user[x]='';
			}
			var user = this.user;
			var formstate = this.formstate;
			Vue.nextTick(function () {
				for(var x in user)
				{
					formstate[x].$submitted = false;
					formstate[x].$touched = false;

				}
				Vue.nextTick(function () {
					var elt = document.querySelectorAll("form > .form-group");
					for(var i = 0 ; i < elt.length ; i++)
					{
						if(elt[i].nodeName!='SELECT')
							elt[i].className = 'form-group required-field';
					}
				});
			});
			this.formstate.$submitted=false;
		},
		subscribe: function()
		{
			if(this.selectedIndexRegion=="-1")
			{
				this.eventHub.$emit('displayAlert', this.CONSTANTS.SELECT_REGION_ERROR);
				return false;
			}		
			var captchaComponent = this.$refs['subscribeCaptcha'];
			if(captchaComponent.$v.$invalid)
			{
				this.eventHub.$emit('displayAlert',this.CONSTANTS.CAPTCHA_ERROR);
				return false;
			}
			var _this = this;
			if(this.user.password!=this.user.confirmPassword){
				this.eventHub.$emit('displayAlert',this.CONSTANTS.PASSWORD_DONT_MATCH);
				return false;
			}
			else if(this.user.password.length<8){
				this.eventHub.$emit('displayAlert',this.CONSTANTS.PASSWORD_TOO_SHORT);
				return false;
			}
			else if(this.formstate.$invalid)
			{
				this.eventHub.$emit('displayAlert',this.CONSTANTS.SUBSCRIBE_ERROR);
				return false;
			}
			if(this.loading==true)return false;
			this.loading = true;
			var _this = this;
			UserResource.subscribe(this.user, this.captcha, this.regions[this.selectedIndexRegion]).then(
			function(response) {
				_this.loading = false;
				_this.modalSubscribeConfirm.content = _this.CONSTANTS.SUBSCRIBE_SUCCESS;
				_this.modalSubscribeConfirm.visible = true;
				if(_this.$route.name!='subscribe')
					_this.init();
				captchaComponent.captchaReload(true);
				_this.eventHub.$emit('setSelectedIndexRegion',_this.selectedIndexRegion);
			}).catch(
			function(error) {
				_this.loading = false;
				var status = (error.response ? error.response.status : -1);
				switch(status)
				{
					case 401:
							var msg = _this.CONSTANTS.CAPTCHA_ERROR;
							break;		
					case 409:
							var msg = _this.CONSTANTS.ACCOUNT_ALREADY_CREATE_ERROR;
							break;
					default:
							var msg = _this.CONSTANTS.SUBSCRIBE_ERROR;
							break;
				}
				_this.$refs['subscribeCaptcha'].captchaReload();
				_this.eventHub.$emit('displayAlert',msg);
			});
		},

		openCgu : function(){
			if(!this.$store.state.variantManager.isComfortlife())return false;
			WindowService.openRedirectUrl('gdpr','_blank',this.inApp);
		},
		openDownloadPage: function(){
			WindowService.openDownloadApp('_blank',this.inApp);
		},
		/*Captcha*/
		inputCaptcha : function(captcha){
			this.captcha=captcha;
		},
		subscribeFinished : function()
		{
			if(this.$route.name=='subscribe')
			{
				this.$router.push({ name: 'login', params: {user: this.user, regionIndex: this.selectedIndexRegion }});
			}
			else
			{
				this.modalSubscribeConfirm.visible = false
			}
		},
		changeRegion : function()
		{
			if(!this.inApp && (this.regions[this.selectedIndexRegion].url.indexOf('dev')==-1))
			{
				window.open(this.regions[this.selectedIndexRegion].url,'_self');
			}
			else
			{
				this.selectedRegion = this.regions[this.selectedIndexRegion];
				this.$refs['subscribeCaptcha'].captchaReload(true,this.selectedRegion);
			}
		}
	}
}
